<template lang="pug">
	.mode-switch-container
		.eye-wrapper
			img(:src='require(`@/assets/${eye}`)')
		label.mode-switch(for='checkbox' data-tooltip='Click to change mode')
			input#checkbox.mode-switch-input(type='checkbox' :checked='checked' @change='switchTheme')
			.slider.round
</template>

<script>
export default {
	name: 'mode-switcher',
	data: function() {
		return {
			theme: 'light',
			eye: 'eye-open.svg',
			checked: false,
		}
	},
	watch: {
		theme: function() {
			this.eye = this.theme === 'dark' ? 'eye-close.svg' : 'eye-open.svg';

			document.documentElement.setAttribute('data-mode', this.theme); //sets mode
			this.$store.commit('setMode', this.theme); //saves mode
		}
	},
	created: function() {
		this.theme = this.$store.state.mode;
		this.checked = this.theme === 'dark' ? true : false;
	},
	methods: {
		switchTheme(e) {
			if (e.target.checked) {
				this.theme = 'dark';				
			} else {
				this.theme = 'light';
			}
		}
	},
}
</script>

<style scoped>
.mode-switch-container {
	display: flex;
	margin-top: 1px;
}

.eye-wrapper {
	width: 24px;
	height: 24px;

	background: var(--color-secondary-background);
	border-radius: 50px;
	margin-right: 5px;
	text-align: center;
}

.eye-wrapper img {
	margin: 6px 0 0 0;
	user-select: none;
	pointer-events: none;
}

:root[data-mode="dark"] .eye-wrapper img {
	margin: 9px 0 0 0;
}

.mode-switch {
	width: 44px;
	-webkit-tap-highlight-color: transparent; /*prevents grey highlighting on click at mobile webkit browsers*/
}

.mode-switch:focus,
.mode-switch:active {
	background: transparent; /*prevents grey highlighting on click at mobile firefox browser*/
}

.mode-switch input {
	display: none;
}

.slider {
	position: absolute;
	
	width: 44px;
	height: 24px;

	border-radius: 50px;
	background-color: var(--color-tertiary-background);

	cursor: pointer;
}

.slider:after {
	position: absolute;
	content: '';
	
	width: 16px;
	height: 16px;

	background-color: var(--color-primary);
	border-radius: 40px;

	bottom: 4px;
	right: 4px;
	
	transition: .4s;
	display: flex;
	align-items: center;
	justify-content: center;
}

input:checked + .slider:after {
	transform: translateX(-21px);
}
</style>