<template lang="pug">
.card(:style='cssProps' @click="routeTo")
	.image-wrapper(v-if='windowWidth > 480')
		img(:src="require('@/assets/' + image + '')")
	button {{ btnTitle }}
</template>

<script>
import { windowWidth } from '@/mixins';

export default {
	name: 'homepage-card',
	mixins: [
		windowWidth,
	],
	props: {
		image: String,
		btnTitle: String,
		bgColor: String,
		dest: String,
	},
	computed: {
		cssProps() {
			return {
				'--bg-color': this.bgColor,
			}
		},
	},
	methods: {
		routeTo: function() {
			this.$router.push({path: '/' + this.dest});
			this.$emit('openPage');
		}
	}
};
</script>

<style scoped>
.card:hover > button {
	background-color: var(--color-tertiary-background);
	color: #FF868E;
}

.card:hover > .image-wrapper {
	border: 4px solid #ffffff;
}

.card.active > button,
.card:active > button {
	background-color: #FF868E;	
	color: #FFFFFF;
}

.card.active > .image-wrapper,
.card:active > .image-wrapper {
	border: 4px solid #FBE0DC;
}

.image-wrapper {
	height: 198px;
	width: 138px;
	background-color: var(--bg-color);
	border: 4px solid rgba(255, 255, 255, 0.6);
	box-sizing: border-box;
	border-radius: 20px;

	margin-bottom: 10px;
	text-align: center;
}

img {
	/* prevent selecting and dragging */
	user-select: none;
	pointer-events: none;

	/* centering */
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

button {
	width: 138px;
	height: 36px;
	background: var(--color-secondary-background);
	border-width: 0;
	border-radius: 10px;
	text-transform: uppercase;

	font-weight: 500;
	font-size: 12px;
	line-height: 16px;

	text-align: center;
	letter-spacing: 2px;

	color: #FF868E;
	cursor: pointer;
}

@media screen and (min-width: 1024px) {
	.card {
		width: 138px;
		height: 198px;
		cursor: pointer;
	}
}

@media screen and (min-width: 480px) and (max-width: 1024px) {
	.card {
		width: 138px;
		height: 198px;
		cursor: pointer;
	}
}

@media screen and (max-width: 480px) {
	.card {
		height: 36px;
		cursor: pointer;
	}

	.card button {
		width: 100%;
		max-width: 335px;
	}
}
</style>