/**
  * Dependencies
**/

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

/**
  * Plugin
**/

Vue.use(Vuex);

/**
  * Export
**/

export default new Vuex.Store({
	state: {
		mode: 'light',
		page: 'home',
	},
	mutations: {
		setMode(state, data){
			state.mode = data;
		},
		setPage(state, page){
			state.page = page;
		}
	},
	actions: {
	},
	modules: {
	},
	plugins: [createPersistedState()],
})
