export default class Image {
	constructor() {
		this.API_KEY = process.env.VUE_APP_API_KEY;
	}

	get(id) {
		return fetch('https://api.thedogapi.com/v1/images/' + id, {
			method: 'GET',
			headers: {
				'x-api-key': this.API_KEY,
			},
		})
			.then(res => res.json())
			.catch(err => console.error(err));
	}

	search(query) {
		return fetch('https://api.thedogapi.com/v1/images/search?' + query, {
			method: 'GET',
			headers: {
				'x-api-key': this.API_KEY,
			},
		}) 
			.then(res => res.json())
			.catch(err => console.error(err));
	}

	upload(image) {
		return fetch('https://api.thedogapi.com/v1/images/upload', {
			method: 'POST',
			headers: {
				'x-api-key': this.API_KEY,
			},
			body: image,
		}) 
			.then(res => res.json())
			.catch(err => console.error(err));
	}
}