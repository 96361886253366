export default class Vote {
	constructor() {
		this.API_KEY = process.env.VUE_APP_API_KEY;
	}

	give(payload) {
		return fetch('https://api.thedogapi.com/v1/votes', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-api-key': this.API_KEY,
			},
			body: JSON.stringify({
				value: payload.value,
				image_id: payload.image_id,
			})
		}) 
			.catch(err => console.error(err));
	}

	get() {
		return fetch('https://api.thedogapi.com/v1/votes', {
			method: 'GET',
			headers: {
				'x-api-key': this.API_KEY,
			},
		}) 
			.then(res => res.json())
			.catch(err => console.error(err));
	}

}