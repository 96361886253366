<template lang="pug">
.container(v-if='!hide')
	.modal(ref='modalEl' v-click-outside='closeModal')
		.close-btn(@click='closeModal')
		h1.modal-title Upload a .jpg or .png Dog image
		p.modal-clarify
			| Any uploads must comly with the 
			a.text-link(href='https://www.thedogapi.com/privacy') upload guidelines
			|  or face deletion
		form.upload-field(ref='uploadField' @drop.prevent='addFile' @dragover.prevent='dragover_handler' 
			:class='fileStatus === "FAIL_UPLOAD" ? "failed-upload" : ""')
			img.pic-to-upload(v-if='["FAIL_UPLOAD", "SELECTED", "UPLOADING"].includes(fileStatus)' :src='picToUpload')
			img.placeholder-pic(v-else :src='placeholderPic')
			p(v-if='fileStatus === "NONE"')
				b Drag here
				|  your file or 
				label.text-btn(for='upload-file') Click here
				|  to upload
			input#upload-file(type='file' accept='.png,.jpg' multiple='false' ref='fileUpload' @input='fileAdd_handler')
		span.status-text {{ uploadStatus }}
		.upload-btn(ref='uploadBtn' v-if='fileStatus === "UPLOADING" || fileStatus === "SELECTED"' @click='uploadFile')
			img(v-if='fileStatus === "UPLOADING"' src='@/assets/uploading-white.svg')
			span {{ uploadBtnTitle }}
		.upload-result(v-if='fileStatus === "SUCCESS_UPLOAD"')
			img(src='@/assets/success-green.svg')
			span Thanks for the Upload - Dog found!
		.upload-result(v-else-if='fileStatus === "FAIL_UPLOAD"')
			img(src='@/assets/error-red.svg')
			span No Dog found - try a different one
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { Image } from '@/services';

const ImageService = new Image();

export default {
	name: 'upload-modal',
	directives: {
    ClickOutside,
  },
	data: function(){
		return {
			uploadStatus: 'No file-selected',
			fileStatus: 'NONE',
			placeholderPic: require('@/assets/pic.svg'),
			picToUpload: null,
			uploadBtnTitle: 'upload photo',
		}
	},
	props: {
		hide: Boolean,
	},
	mounted: function() {
		this.popupItem = this.modalEl;
	},
	methods: {
		closeModal() {
			Object.assign(this.$data, this.$options.data.call(this));
			this.$emit('close');
		},
		addFile(e) {
			e.preventDefault();
			let droppedFiles = e.dataTransfer.files;
			if(!droppedFiles) return;

			let files = [...droppedFiles];
			if (files.length > 1 && this.$refs.fileUpload.files.length < 1){
				// Some error notification
			} else if(files.length == 1 && files[0].name.endsWith('.png')){
				this.$refs.fileUpload.files = droppedFiles;
				this.setFormBackground(droppedFiles[0]);
			} else {
				// Some error notification
			}
		},
		fileAdd_handler(e) {
			this.setFormBackground(e.target.files[0]);
		},
		setFormBackground(file) {
			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.addEventListener("load", () => {
				this.fileStatus = 'SELECTED';
				this.picToUpload = reader.result;
				this.uploadStatus = 'Image File Name: ' + file.name;
			}, false);
		},
		dragover_handler() {
			this.$refs.uploadField.classList.add('is-dragover');
		},
		async uploadFile(){
			if (this.$refs.fileUpload.files.length > 0) {
				this.uploadBtnTitle = 'uploading';
				this.fileStatus = 'UPLOADING';

				let formData = new FormData();
				formData.append('file', this.$refs.fileUpload.files[0]);

				let res = await ImageService.upload(formData); 

				if (res.status === 400) {
					this.fileStatus = 'FAIL_UPLOAD';
				} else {
					this.uploadStatus = 'No file-selected';
					this.fileStatus = 'SUCCESS_UPLOAD';
				}
			}
		}
	},
}
</script>

<style scoped>
.container {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-color: rgba(29, 29, 29, 0.6);
	z-index: 100;
}

.modal {
	background: #F8F8F7;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

:root[data-mode='dark'] .modal {
	background-color: #282828;
}

.close-btn {
	position: absolute;
	width: 40px;
	height: 40px;
	border-radius: 10px;
	right: 20px;
	top: 20px;
	background-image: url(./../assets/cross-red.svg);
	background-color: var(--color-primary-button-background);
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}

.modal-title {
	font-weight: 500;
	font-size: 36px;
	color: var(--color-primary-dark);
	margin: 80px 0 10px 0;
}

.modal-clarify {
	margin: 0;
	font-size: 20px;
	line-height: 30px;
	color: #8C8C8C;
}

.text-link {
	color: var(--color-primary);
	text-decoration: none;
}

.upload-field {
	border: 2px dashed #FBE0DC;
	border-radius: 20px;
	background-color: var(--color-primary-button-background);
	background-repeat: no-repeat;
	background-position: center;
	background-size: initial;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

:root[data-mode='dark'] .upload-field {
	border: 2px dashed rgba(255, 134, 142, 0.2);
}

.failed-upload {
	background-color: #FBE0DC;
	border: 2px dashed var(--color-primary);
}

.pic-to-upload {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.placeholder-pic {
	position: absolute;
	object-fit: none;
	z-index: 0;
	pointer-events: none;
}

:root[data-mode='dark'] .placeholder-pic {
	filter: opacity(5%);
}

.upload-field p {
	color: var(--color-primary-dark);
	margin: 0;
	font-size: 20px;
	z-index: 10;
}

.text-btn {
	font-weight: bold;
	cursor: pointer;
}

#upload-file {
	display: none;
}

.status-text {
	font-size: 20px;
	color: #8C8C8C;
}

.upload-btn {
	margin-top: 20px;
	width: 172px;
	height: 40px;

	background-color: var(--color-primary);
	color: #FFFFFF;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	gap: 10px;
}

.upload-btn span {
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	/* identical to box height, or 133% */

	text-align: center;
	letter-spacing: 2px;

	pointer-events: none;
	text-transform: uppercase;
}

.upload-btn:hover {
	background-color: #FBE0DC;
	color: var(--color-primary);
}

.upload-btn:active {
	background-color: var(--color-primary);
	color: #FFFFFF;
}

.upload-result {
	height: 20px;
	background-color: var(--color-primary-button-background);
	border-radius: 10px;
	padding: 20px;
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: flex-start;
	margin-top: 20px;
}

.upload-result span {
	font-size: 16px;
	line-height: 24px;
	color: #8C8C8C;
}

@media screen and (min-width: 1024px) {
	.container {
		padding: 30px;
	}

	.modal {
		width: 640px;
		height: 800px;
		border-radius: 20px;
	}

	.upload-result {
		width: 600px;
	}

	.upload-field {
		width: 558px;
		height: 280px;
		padding: 20px 41px;
		margin: 40px 0 20px 0;
	}

	.placeholder-pic {
		object-fit: none;
	}
}

@media screen and (max-width: 1024px) {
	.container {
		padding: 0;
	}

	.modal {
		width: 100%;
		height: 100%;
		border-radius: 0;
		box-sizing: border-box;
	}

	.upload-field {
		width: 100%;
		height: auto;
		padding: 0 0 50% 0;
		margin: 20px 0 10px 0;
		position: relative;
		box-sizing: border-box;
	}

	.placeholder-pic {
		top: 50%;
		width: 100px;
		height: 100px;
		object-fit: contain;
		transform: translateY(-50px);
	}

	.upload-field p {
		position: absolute;
		top: 50%;
		transform: translateY(-30px);
		height: 60px;
		max-width: 200px;
	}

	.pic-to-upload {
		width: calc(100% - 40px);
		height: calc(100% - 20px);
		object-fit: cover;
		position: absolute;
		top: 10px;
		border-radius: 20px;
	}
}

@media screen and (min-width: 480px) and (max-width: 1024px) {
	.upload-result {
		width: calc(100% - 40px);
	}

	.upload-field {
		max-width: 640px;
		max-height: 280px;
	}

	.modal-title {
		margin-top: .85em;
		text-align: center;
		font-size: 34px;
	}
}

@media screen and (max-width: 480px) {
	.close-btn {
		height: 60px;
		width: 60px;
	}

	.modal-title {
		font-size: 20px;
		margin: 80px 0 20px 0;
	}

	.modal-clarify {
		text-align: center;
	}

	.status-text {
		text-align: center;
	}

	.upload-btn {
		width: 100%;
	}

	.upload-result {
		width: calc(100% - 40px);
	}
}
</style>