<template lang="pug">
.page-container
	.page-header
		back-button
		.page-title
			span voting
	.dog-image-wrapper
		.dog-image(:style="{ backgroundImage: 'url(' + image + ')' }")
			.loading-spinner(v-if='isRetrievingData')
				clip-loader(color="#ff868e" size='100px')
			vote-options(:id='id' @vote='processVote')
	.logs-container
		log-item(v-for='item in logs' :key='item.id' :vote='item')
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import BackButton from '@/components/BackButton.vue';
import VoteOptions from '@/components/VoteOptions.vue';
import LogItem from '@/components/LogItem.vue';
import { Image } from '@/services';

const ImageService = new Image();

export default {
	name: 'voting-page',
	components: {
		ClipLoader,
		BackButton,
		VoteOptions,
		LogItem,
	},
	data: function () {
		return {
			id: '',
			image: '',
			logs: [],
			isRetrievingData: true,
		}
	},
	created: async function() {
		this.$store.commit('setPage', 'voting');

		await this.getDog();
	},
	methods: {
		async getDog() {
			this.isRetrievingData = true;
			this.image = '';

			let dog = await ImageService.search('limit=1&size=full')
				.then(res => res[0]);

			this.id = dog.id;
			this.image = dog.url;
			this.isRetrievingData = false;
		},
		getTime() {
			let time = new Date();

			let hours = time.getHours();
			if ((hours + '').length < 2) hours = hours + '0';

			let minutes = time.getMinutes();
			if ((minutes + '').length < 2) minutes = '0' + minutes;

			return hours + ':' + minutes; 
		},
		processVote(data) {
			this.logs.unshift({
				id: this.id,
				event: data.event,
				detail: data.detail,
				time: this.getTime(),
			});

			if (this.logs.length > 5) this.logs.pop();
			
			if (data.event === 'Likes' || data.event === 'Dislikes') {
				this.getDog();
			}
		},
	}
};
</script>

<style scoped>
.page-container{
	width: 100%;
}

.page-header {
	display: flex;
	gap: 10px;
}

.page-title {
	width: 146px;
	height: 40px;
	background: var(--color-primary);
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.page-title span {
	font-weight: 500;

	text-align: center;
	letter-spacing: 2px;

	color: #FFFFFF;
	text-transform: uppercase;
}

.dog-image-wrapper {
	max-width: 100%;
}

.dog-image {
	position: relative;
	border-radius: 20px;
	padding-bottom: 56.25%; /* aspect ratio 16/9 */
	background-size: cover;
}

.logs-container {
	margin-top: 52px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}
</style>