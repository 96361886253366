<template lang="pug">
.log-item
	.time-wrapper
		span.time {{ vote.time }}
	.text-info-container
		span Image ID: 
		span.item-id {{ vote.id }}
		span {{ action + vote.event }} 
	img.action-pic(v-if='actionPic.length > 0' :src='require(`@/assets/${actionPic}.svg`)' ) 
</template>

<script>
export default {
	name: 'log-item',
	data: function() {
		return {
			picture: {
				fav: 'heart-red',
				like: 'like-green',
				dislike: 'dislike-yellow',
			},
			eventText: {
				add: ' was added to ',
				remove: ' was removed from ',
			},
			place: '',
		}
	},
	props: {
		vote: Object,
	},
	computed: {
		actionPic: function() {
			let pic = '';
			if (this.vote.event === 'Favourites' && this.vote.detail === 'add') {
				pic = this.picture.fav;
			} else if (this.vote.event === 'Likes') {
				pic = this.picture.like;
			} else if (this.vote.event === 'Dislikes') {
				pic = this.picture.dislike;
			}

			return pic;
		},
		action: function() {
			if (this.vote.event === 'Favourites') {
				return this.vote.detail === 'add' ? this.eventText.add : this.eventText.remove;
			} else {
				return this.eventText.add;
			}
		}
	},
}
</script>

<style scoped>
.log-item {
	background: var(--color-secondary-button-background);
	border-radius: 10px;
	padding: 15px;
	display: flex;
	align-items: center;

	font-weight: normal;
	font-size: 16px;
}

.time-wrapper {
	width: 41px;
	background-color: #ffffff;
	color: var(--color-primary-dark);
	border-radius: 5px;
	padding: 3px 10px;
}

:root[data-mode="dark"] .time-wrapper {
	background-color: #1d1d1d;
}

.text-info-container {
	color: #8C8C8C;
}

.time-wrapper {
	margin-right: 20px;
}

.item-id {
	color: var(--color-primary-dark);
}

.action-pic {
	margin-left: auto;
	width: 20px;
}

@media screen and (min-width: 1024px) {
	.log-item {
		height: 30px;
	}
}

@media screen and (min-width: 480px) and (max-width: 1024px) {
	.log-item {
		height: 30px;
	}
}

@media screen and (max-width: 480px) {
	.log-item {
		flex-wrap: wrap;
		gap: 10px;
	}

	.text-info-container {
		order: 1;
	}
}
</style>