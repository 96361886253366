import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import SearchHeader from '@/views/SearchHeader.vue';
import Voting from '@/views/Voting.vue';
import Breeds from '@/views/Breeds.vue';
import Breed from '@/views/Breed.vue';
import Gallery from '@/views/Gallery.vue';
import Search from '@/views/Search.vue';
import Likes from '@/views/Likes.vue';
import Favourites from '@/views/Favourites.vue';
import Dislikes from '@/views/Dislikes.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
	},
	{
		path: '/s',
		component: SearchHeader,
		beforeEnter(to, from, next) {
			if (to.fullPath === '/s') {
				next({ name: 'home' });
			} else {
				next();
			}
		},
		children: [
			{
				path: '/voting',
				name: 'voting',
				component: Voting,
			},
			{
				path: '/breeds',
				name: 'breeds',
				component: Breeds,
			}, {
				path: '/breeds/:name',
				name: 'breed',
				component: Breed,
			}, {
				path: '/gallery',
				name: 'gallery',
				component: Gallery,
			},
			{
				path: '/search',
				name: 'search',
				component: Search,
			},
			{
				path: '/likes',
				name: 'likes',
				component: Likes,
			},
			{
				path: '/favourites',
				name: 'favourites',
				component: Favourites,
			},
			{
				path: '/dislikes',
				name: 'dislikes',
				component: Dislikes,
			},
		]
	},
]

export default new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});