<template lang="pug">
	.vote-options-container
		.vote-option.vote-like-option(@click="voteDog('Likes')")
		.vote-option.vote-fav-option(ref='favBtn' @click="toggleFavDog")
		.vote-option.vote-dislike-option(@click="voteDog('Dislikes')")
</template>

<script>
import { Favourite, Vote } from '@/services';

const FavouriteService = new Favourite();
const VoteService = new Vote();

export default {
	name: 'vote-options',
	data: function() {
		return {
			isFav: false,
			favID: null,
		}
	},
	props: {
		id: String,
	},
	methods: {
		async voteDog(vote) {
			let voteNum = vote === 'Likes' ? 1 : 0;

			await VoteService.give({
				value: voteNum,
				image_id: this.id,
			}).then(() => this.$emit('vote', {
				event: vote,
			}));
		},
		toggleFavDog() {
			if (this.isFav){
				this.deleteFavDog();
			} else {
				this.addFavDog();
			}

			this.$refs.favBtn.classList.toggle('fav-dog');
		},
		async addFavDog() {
			await FavouriteService.add(this.id) 
				.then(res => this.favID = res.id)
				.then(() => this.$emit('vote', {
					event: 'Favourites',
					detail: 'add',
				}));

			this.isFav = true;
		},
		async deleteFavDog() {
			await FavouriteService.remove(this.favID)
				.then(() => this.$emit('vote', {
					event: 'Favourites',
					detail: 'remove',
				}));

			this.isFav = false;
		}
	}
}
</script>

<style scoped>
.vote-options-container {
	position: absolute;
	bottom: 0;
	left: 50%;

	background: #FFFFFF;
	border: 4px solid var(--color-secondary-background);
	border-color: var(--color-secondary-background);
	border-radius: 20px;

	display: flex;
	gap: 4px;
	overflow: hidden;
}

.vote-option {
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}

.vote-like-option {
	background-image: url(./../assets/like-white.svg);
	background-color: #97EAB9;	
}

.vote-fav-option {
	background-image: url(./../assets/heart-white.svg);
	background-color: #FF868E;
}

.vote-dislike-option {
	background-image: url(./../assets/dislike-white.svg);
	background-color: #FFD280;
}

.vote-like-option:hover {
	background-image: url(./../assets/like-green.svg);
	background-color: var(--color-secondary-green);
}

.vote-fav-option:hover {
	background-image: url(./../assets/heart-red.svg);
	background-color: var(--color-secondary-red);
}

.vote-dislike-option:hover {
	background-image: url(./../assets/dislike-yellow.svg);
	background-color: var(--color-secondary-yellow);
}

.fav-dog,
.vote-fav-option:active {
	background-image: url(./../assets/heart-white-fill.svg);
	background-color: #FF868E;
}

@media screen and (min-width: 480px) {
	.vote-options-container {
		width: 248px;
		height: 80px;
		margin: 0 0 -40px -124px;
	}

	.vote-option {
		width: 80px;
		height: 80px;
	}
}

@media screen and (max-width: 480px) {
	.vote-options-container {
		width: 188px;
		height: 60px;
		margin: 0 0 -30px -94px;
	}

	.vote-option {
		width: 60px;
		height: 60px;
		background-size: 22.5px;
	}
}
</style>