<template lang="pug">
svg#logo(width='106' height='24' viewbox='0 0 106 24' fill='none' xmlns='http://www.w3.org/2000/svg' @click='routeTo')
	g(clip-path='url(#clip0)')
		path.paw-part(d='M7.84067 12.4577C7.84067 9.90778 9.90775 7.8407 12.4576 7.8407L19.3831 7.8407C21.9329 7.8407 24 9.90778 24 12.4577C24 15.0075 21.9329 17.0746 19.3831 17.0746H12.4576C9.90775 17.0746 7.84067 15.0075 7.84067 12.4577Z')
		path.paw-part(d='M12.4576 24C9.90775 24 7.84067 21.9329 7.84067 19.383L7.84067 12.4576C7.84067 9.90772 9.90775 7.84063 12.4576 7.84063C15.0075 7.84063 17.0746 9.90772 17.0746 12.4576V19.383C17.0746 21.9329 15.0075 24 12.4576 24Z')
		path.paw-part(d='M11.5424 2.8856C11.5424 4.47927 10.2505 5.7712 8.65678 5.7712C7.06311 5.7712 5.77118 4.47927 5.77118 2.8856C5.77118 1.29193 7.06311 0 8.65678 0C10.2505 0 11.5424 1.29193 11.5424 2.8856Z')
		path.paw-part(d='M5.7712 16.7366C5.7712 18.3302 4.47927 19.6222 2.8856 19.6222C1.29193 19.6222 0 18.3302 0 16.7366C0 15.1429 1.29193 13.851 2.8856 13.851C4.47927 13.851 5.7712 15.1429 5.7712 16.7366Z')
		path.paw-part(d='M19.6221 2.8856C19.6221 4.47927 18.3302 5.7712 16.7365 5.7712C15.1428 5.7712 13.8509 4.47927 13.8509 2.8856C13.8509 1.29193 15.1428 0 16.7365 0C18.3302 0 19.6221 1.29193 19.6221 2.8856Z')
		path.paw-part(d='M5.7712 8.65684C5.7712 10.2505 4.47927 11.5424 2.8856 11.5424C1.29193 11.5424 0 10.2505 0 8.65684C0 7.06317 1.29193 5.77124 2.8856 5.77124C4.47927 5.77124 5.7712 7.06317 5.7712 8.65684Z')
		path.letter(d='M31.8944 18.8V6.43518H37.6928C38.6656 6.43518 39.4592 6.62078 40.0736 6.99198C40.7008 7.36318 41.168 7.86878 41.4752 8.50878C41.7824 9.14878 41.936 9.87838 41.936 10.6976C41.936 11.5296 41.7568 12.2656 41.3984 12.9056C41.0528 13.5456 40.5536 14.0448 39.9008 14.4032C39.2608 14.7488 38.4992 14.9216 37.616 14.9216H34.4864V18.8H31.8944ZM34.4864 12.8672H37.0784C37.8208 12.8672 38.3776 12.6752 38.7488 12.2912C39.1328 11.8944 39.3248 11.3632 39.3248 10.6976C39.3248 9.98078 39.1456 9.43678 38.7872 9.06558C38.4288 8.68158 37.8912 8.48958 37.1744 8.48958H34.4864V12.8672Z')
		path.letter(d='M48.1118 18.992C46.5374 18.992 45.2894 18.6016 44.3678 17.8208C43.4462 17.0272 42.9854 15.8304 42.9854 14.2304C42.9854 12.784 43.3694 11.6384 44.1374 10.7936C44.9182 9.93598 46.0766 9.50718 47.6126 9.50718C49.0206 9.50718 50.0958 9.87838 50.8382 10.6208C51.5934 11.3504 51.971 12.3104 51.971 13.5008V15.152L45.3854 15.152C45.5262 15.8816 45.859 16.3808 46.3838 16.6496C46.9214 16.9184 47.6766 17.0528 48.6494 17.0528C49.1358 17.0528 49.6286 17.008 50.1278 16.9184C50.6398 16.8288 51.075 16.7136 51.4334 16.5728V18.416C51.011 18.608 50.5182 18.7488 49.955 18.8384C49.3918 18.9408 48.7774 18.992 48.1118 18.992ZM45.3854 13.5584H49.7054V13.0592C49.7054 12.5344 49.5518 12.1248 49.2446 11.8304C48.9374 11.5232 48.419 11.3696 47.6894 11.3696C46.8318 11.3696 46.2302 11.5424 45.8846 11.888C45.5518 12.2336 45.3854 12.7904 45.3854 13.5584Z')
		path.letter(d='M57.3853 18.992C56.3357 18.992 55.5549 18.7168 55.0429 18.1664C54.5437 17.616 54.2941 16.8672 54.2941 15.92V11.696H53.0077V9.69918H54.2941V7.74078L56.8861 6.97278V9.69918H59.1901L59.0365 11.696H56.8861V15.7472C56.8861 16.2464 57.0013 16.592 57.2317 16.784C57.4621 16.9632 57.8205 17.0528 58.3069 17.0528C58.6653 17.0528 59.0365 16.9888 59.4205 16.8608V18.6464C59.1389 18.7616 58.8317 18.8448 58.4989 18.896C58.1661 18.96 57.7949 18.992 57.3853 18.992Z')
		path.letter(d='M63.9571 18.992C63.2787 18.992 62.6323 18.9408 62.0179 18.8384C61.4035 18.7488 60.9107 18.6336 60.5395 18.4928V16.3424C60.9875 16.5216 61.4931 16.6624 62.0563 16.7648C62.6195 16.8544 63.1379 16.8992 63.6115 16.8992C64.2515 16.8992 64.6995 16.8608 64.9555 16.784C65.2243 16.7072 65.3587 16.5344 65.3587 16.2656C65.3587 15.9584 65.1539 15.7344 64.7443 15.5936C64.3475 15.4528 63.7523 15.2544 62.9587 14.9984C62.1267 14.7168 61.4867 14.3776 61.0387 13.9808C60.5907 13.584 60.3667 12.9952 60.3667 12.2144C60.3667 11.344 60.6803 10.6784 61.3075 10.2176C61.9475 9.74398 62.9715 9.50718 64.3795 9.50718C64.9427 9.50718 65.4739 9.55198 65.9731 9.64158C66.4723 9.71838 66.8947 9.81438 67.2403 9.92958V12.0608C66.8947 11.8944 66.4979 11.7728 66.0499 11.696C65.6019 11.6064 65.1859 11.5616 64.8019 11.5616C64.2515 11.5616 63.8099 11.6 63.4771 11.6768C63.1571 11.7536 62.9971 11.92 62.9971 12.176C62.9971 12.4576 63.1699 12.656 63.5155 12.7712C63.8739 12.8864 64.4243 13.0592 65.1667 13.2896C65.8963 13.5072 66.4659 13.744 66.8755 14C67.2851 14.256 67.5731 14.5632 67.7395 14.9216C67.9059 15.2672 67.9891 15.7024 67.9891 16.2272C67.9891 18.0704 66.6451 18.992 63.9571 18.992Z')
		path.letter(d='M69.7882 18.8V6.43518L75.5866 6.43518C76.5594 6.43518 77.353 6.62078 77.9674 6.99198C78.5946 7.36318 79.0618 7.86878 79.369 8.50878C79.6762 9.14878 79.8298 9.87838 79.8298 10.6976C79.8298 11.5296 79.6506 12.2656 79.2922 12.9056C78.9466 13.5456 78.4474 14.0448 77.7946 14.4032C77.1546 14.7488 76.393 14.9216 75.5098 14.9216H72.3802V18.8H69.7882ZM72.3802 12.8672H74.9722C75.7146 12.8672 76.2714 12.6752 76.6426 12.2912C77.0266 11.8944 77.2186 11.3632 77.2186 10.6976C77.2186 9.98078 77.0394 9.43678 76.681 9.06558C76.3226 8.68158 75.785 8.48958 75.0682 8.48958H72.3802V12.8672Z')
		path.letter(d='M83.749 18.992C83.173 18.992 82.6354 18.8896 82.1362 18.6848C81.6498 18.4672 81.253 18.1536 80.9458 17.744C80.6514 17.3216 80.5042 16.7968 80.5042 16.1696C80.5042 15.2736 80.8178 14.5568 81.445 14.0192C82.085 13.4816 83.0258 13.2128 84.2674 13.2128H86.9554V12.9632C86.9554 12.4 86.7954 12.0032 86.4754 11.7728C86.1682 11.5424 85.541 11.4272 84.5938 11.4272C83.557 11.4272 82.5586 11.5872 81.5986 11.9072V10.0832C82.021 9.91678 82.533 9.78238 83.1346 9.67998C83.749 9.56478 84.4146 9.50718 85.1314 9.50718C86.501 9.50718 87.557 9.78878 88.2994 10.352C89.0546 10.9024 89.4322 11.792 89.4322 13.0208V18.8H87.1858L87.0514 17.9744C86.693 18.2944 86.2514 18.544 85.7266 18.7232C85.2018 18.9024 84.5426 18.992 83.749 18.992ZM84.4594 17.2832C85.0354 17.2832 85.5346 17.1872 85.957 16.9952C86.3794 16.8032 86.7122 16.56 86.9554 16.2656V14.8256H84.325C83.3138 14.8256 82.8082 15.2416 82.8082 16.0736C82.8082 16.88 83.3586 17.2832 84.4594 17.2832Z')
		path.letter(d='M93.0479 18.8L90.6479 9.69918H93.2399L94.6223 15.6128L96.1391 10.8704V9.69918H98.1167L99.8447 15.6128L101.189 9.69918H103.762L101.381 18.8H98.9999L97.2527 13.4816L95.4671 18.8H93.0479Z')
	defs
		clipPath#clip0
			rect(width='106' height='24' fill='white')
</template>

<script>
export default {
	name: 'app-logo',
	methods: {
		routeTo: function() {
			this.$router.push({path: '/'});
		}
	}
}
</script>

<style scoped>
#logo {
	width: 106.16px;
	height: 24px;
	cursor: pointer;
}

.paw-part {
	fill: var(--color-primary);
}

.letter {
	fill: var(--color-primary-dark);
}
</style>