<template lang="pug">
#app
	main-section(ref='sidebar')
	router-view(@closeSidebar='closeSidebar')
</template>

<script>
import MainSection from '@/components/MainSection.vue';

export default {
	components: {
		MainSection,
	},
	created: function(){
		document.documentElement.setAttribute('data-mode', this.$store.state.mode);
	},
	methods: {
		closeSidebar() {
			this.$refs.sidebar.openSidebar();
		}
	},
}
</script>

<style>
:root {
	--color-primary: #ff868e;
	--color-primary-background: #f8f8f7;
	--color-secondary-background: #ffffff;
	--color-tertiary-background: #fbe0dc;

	--color-primary-button-background: #ffffff;
	--color-secondary-button-background: #f8f8f7;
	--color-primary-tile-button-background: #ffffff;

	--color-primary-dark: #1d1d1d;

	--color-secondary-green: #e0f9ea;
	--color-secondary-red: #ffdadd;
	--color-secondary-yellow: #fff1d9;
}

:root[data-mode="dark"] {
	--color-primary-background: #1d1d1d;
	--color-secondary-background: #343434;
	--color-tertiary-background: rgba(255, 134, 142, 0.2);

	--color-primary-button-background: rgba(255, 255, 255, 0.05);
	--color-secondary-button-background: #3e3e3e;
	--color-primary-tile-button-background: #282828;

	--color-primary-dark: #ffffff;
}

* {
	font-family: Jost;
	font-style: normal;
}

html,
body {
	height: 100%;
	width: 100%;
	margin: 0;
}

body {
	background-color: var(--color-primary-background);
}

#app {
	min-height: 100%;
	min-width: 100%;
	box-sizing: border-box;
	display: grid;
	justify-content: center;
}

@media screen and (min-width: 1024px) {
	#app {
		grid-template-columns: 446px 680px;
		grid-template-rows: 840px;
		grid-column-gap: 137px;

		padding: 30px 30px 30px 147px;
	}
}

@media screen and (min-width: 480px) and (max-width: 1024px) {
	#app {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;

		padding: 30px;
	}
}

@media screen and (max-width: 480px) {
	#app {
		grid-template-columns: 1fr;

		padding: 20px;
	}
}

.page-container {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	background-color: var(--color-secondary-background);
	border-radius: 20px;
	padding: 20px;
	position: relative;
	align-self: center;
}

@media screen and (min-width: 1024px) {
	.page-container {
		min-height: calc(100% - 110px);
		gap: 20px;
	}
}

@media screen and (min-width: 480px) and (max-width: 1024px) {
	.page-container {
		min-height: calc(100% - 70px);
		width: max-content;
		max-width: 100%;
		gap: 20px;
	}
}

@media screen and (max-width: 480px) {
	.page-container {
		width: 100%;
		min-height: calc(100% - 140px);
		gap: 10px;
	}
}

.loading-spinner {
	position: absolute;
	left: 50%;
	top: 50%;
  transform: translate(-50%, -50%);
	z-index: 100;
}
</style>

<style src='@/styles/tiles.css'></style>