export default class Vote {
	constructor() {
		this.API_KEY = process.env.VUE_APP_API_KEY;
	}

	get() {
		return fetch('https://api.thedogapi.com/v1/favourites', {
			method: 'GET',
			headers: {
				'x-api-key': this.API_KEY,
			},
		}) 
			.then(res => res.json())
			.catch(err => console.error(err));
	}

	add(id) {
		return fetch('https://api.thedogapi.com/v1/favourites', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-api-key': this.API_KEY,
			},
			body: JSON.stringify({
				image_id: id,
			})
		})
			.then(res => res.json())
			.catch(err => console.error(err));
	}

	remove(id) {
		return fetch('https://api.thedogapi.com/v1/favourites/' + id, {
			method: 'DELETE',
			headers: {
				'x-api-key': this.API_KEY,
			},
		})
		.catch(err => console.error(err));
	}

	
}