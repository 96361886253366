<template lang="pug">
.container
	.top-search-links-container
		.square-link.sidebar-toggle(v-if='windowWidth <= 1024' @click='openSidebar')
		search-field(:class='isThisPage("search")')
		.square-link.likes-link(:class='isThisPage("likes")' @click='navTo("/likes")')
		.square-link.favs-link(:class='isThisPage("favourites")' @click='navTo("/favourites")')
		.square-link.dislikes-link(:class='isThisPage("dislikes")' @click='navTo("/dislikes")')
	router-view
</template>

<script>
import SearchField from '@/components/SearchField.vue';
import { windowWidth } from '@/mixins';

export default {
	components: {
		SearchField,
	},
	mixins: [
		windowWidth,
	],
	data: function() {
		return {
			openedPage: 'home',
		}
	},
	created: function() {
		this.openedPage = this.$store.state.page;
		
		this.$store.subscribe(mutation => {
			if (mutation.type === 'setPage') {
				this.openedPage = mutation.payload; 
			}
		})	
	},
	methods: {
		isThisPage(page) {
			if (this.openedPage === page) return 'active';
		},
		openSidebar() {
			this.$emit('closeSidebar');
		},
		navTo(route) {
			this.$router.push({
				path: route,
			});
		},
	},
}
</script>

<style scoped>
.container {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.top-search-links-container {
	display: flex;
	gap: 10px;
}

.square-link {
	width: 60px;
	min-width: 60px;
	height: 60px;
	min-height: 60px;
	background-color: var(--color-secondary-background);
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 20px;
	display: flex;
	cursor: pointer;
}

.square-link:hover {
	background-color: var(--color-tertiary-background);
}

.square-link.active,
.square-link:active {
	background-color: var(--color-primary);
}

.sidebar-toggle {
	background-image: url(./../assets/hamburger-lines.svg);
}

.likes-link {
	background-image: url(./../assets/like-red.svg);
}

.favs-link {
	background-image: url(./../assets/heart-red.svg);
}

.dislikes-link {
	background-image: url(./../assets/dislike-red.svg);
}

.square-link.likes-link.active,
.square-link:active.likes-link {
	background-image: url(./../assets/like-white.svg);
}

.square-link.favs-link.active,
.square-link:active.favs-link {
	background-image: url(./../assets/heart-white.svg);
}

.square-link.dislikes-link.active,
.square-link:active.dislikes-link {
	background-image: url(./../assets/dislike-white.svg);
}

@media screen and (max-width: 480px) {
	.top-search-links-container {
		flex-wrap: wrap;
	}

	.search-field {
		order: 1;
	}

	.sidebar-toggle {
		margin-right: auto;
	}
}
</style>