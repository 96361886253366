<template lang="pug">
.square-btn.back-btn(@click='navBack')
</template>

<script>
export default {
	name: 'back-button',
	methods: {
		navBack() {
			this.$router.go(-1);
		},
	},
}
</script>

<style scoped>
.square-btn {
	width: 40px;
	height: 40px;
	background-color: var(--color-tertiary-background);
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 10px;
	cursor: pointer;
}

.square-btn:hover {
	background-color: var(--color-primary);
}

.back-btn {
	background-image: url(./../assets/back-red.svg);
}

.square-btn:hover.back-btn {
	background-image: url(./../assets/back-white.svg);
}
</style>