<template lang="pug">
.girl-pet-img-wrapper(v-if='windowWidth > 1024')
	img(src='@/assets/girl-and-pet.svg')
</template>

<script>
import { windowWidth } from '@/mixins';

export default {
	name: 'homepage',
	mixins: [
		windowWidth,
	],
	created: function() {
		this.$store.commit('setPage', 'home');
	},
};
</script>

<style scoped>
.girl-pet-img-wrapper {
	background-color: var(--color-tertiary-background);
	border-radius: 20px;
	position: relative;
}

img {
	position: absolute;
	height: 1024px;
	right: -30px;
	top: -92px;
}
</style>
