<template lang="pug">
.page-container
	.loading-spinner(v-if='isRetrievingData')
		clip-loader(color="#ff868e" size='100px')
	.page-header
		back-button
		.page-title
			span breeds
		select-box.select-breeds(width='222' :list='breedsList' @selectItem='selectBreed')
		select-box(width='102' :list='limitList' @selectItem='changeItemsOnPage')

		.square-btn.sort-z-a(ref='zaBtn' @click='zaSort')
		.square-btn.sort-a-z(ref='azBtn' @click='azSort')
	.tiles-container
		.tile(
			v-for='dog in breeds.slice(0, itemsOnPage)' :key='dog.name'
			:title='dog.image'
			:style="{ 'background-image': 'url(' + dog.image + ')' }"
		)
			.tile-overlay
				.tile-botton-btn(@click='openBreed(dog.name)')
					span {{ dog.name }}
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import BackButton from '@/components/BackButton.vue';
import SelectBox from '@/components/SelectBox.vue';
import { Breed } from '@/services';

const BreedService = new Breed();

export default {
	name: 'breeds',
	components: {
		ClipLoader,
		BackButton,
		SelectBox,
	},
	data: function() {
		return {
			limitList: [{
				value: 5,
				name: 'Limit: 5',
			}, {
				value: 10,
				name: 'Limit: 10',
			}, {
				value: 15,
				name: 'Limit: 15',
			}, {
				value: 20,
				name: 'Limit: 20',
			}],
			breedsList: [{
				value: 0,
				name: 'All breeds',
			}],
			breeds: [],
			allBreeds: [],
			itemsOnPage: 5,
			isReversed: false,
			isRetrievingData: true,
		}
	},
	beforeCreate: async function() {
		this.$store.commit('setPage', 'breeds');
		let breeds = await BreedService.get();

		for (let breed of breeds) {
			this.breedsList.push({
				value: breed.id,
				name: breed.name,
			});

			this.breeds.push({
				name: breed.name,
				image: breed.image.url,
			});
		}
		
		this.allBreeds = this.breeds;
		this.isRetrievingData = false;
	},
	methods: {
		selectBreed(e) {
			this.breeds = this.allBreeds.filter(el => el.name === e.name);
		},
		changeItemsOnPage(e) {
			this.itemsOnPage = e.value;
		},
		zaSort() {
			this.breeds = this.breeds.reverse();
			this.isReversed = true;

			this.$refs.azBtn.classList.remove('za-active');
			this.$refs.zaBtn.classList.toggle('za-active');
		},
		azSort() {
			if (this.isReversed) {
				this.breeds = this.breeds.reverse();
				this.isReversed = false;
			}

			this.$refs.azBtn.classList.toggle('za-active');
			this.$refs.zaBtn.classList.remove('za-active');
		},
		openBreed(breedName) {
			this.$router.push({path: '/breeds/' + breedName});
		}
	}
};
</script>

<style scoped>
.page-header {
	display: flex;
	gap: 10px;
}

.page-title {
	width: 146px;
	height: 40px;
	background: #FF868E;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.page-title span {
	font-weight: 500;

	text-align: center;
	letter-spacing: 2px;

	color: #FFFFFF;
	text-transform: uppercase;
}

.square-btn {
	box-sizing: border-box;
	width: 40px;
	height: 40px;
	background-color: var(--color-secondary-button-background);
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 10px;
	cursor: pointer;
	border: 2px solid transparent;
}

.square-btn:hover,
.za-active {
	background-color: #f8f8f7;
	border: 2px solid rgba(255, 134, 142, 0.2);
}

.sort-z-a {
	background-image: url(./../assets/sort-za-grey.svg);
}

.sort-a-z {
	background-image: url(./../assets/sort-az-grey.svg);
}

.sort-z-a:hover,
.sort-z-a.za-active {
	background-image: url(./../assets/sort-za-red.svg);
}

.sort-a-z:hover,
.sort-a-z.za-active {
	background-image: url(./../assets/sort-az-red.svg);
}

@media screen and (max-width: 480px) {
	.page-header {
		flex-wrap: wrap;
	}

	.select-container {
		flex-grow: 1;
	}

	.select-breeds {
		width: 100%;
	}
}
</style>