<template lang="pug">
.select-container
	span.select-title(v-if='title') {{ title }}
	details
		summary.select-box(
			:class="{ 'select-box-alt': alt }"
			:style='"width:" + width +"px"'
		) {{ selected.name }}
		ul.select-menu(v-click-outside='closeMenu')
			li.select-item(
				v-for='item in list' :key='item.value'
				@click='selectItem'
				:data-value='item.value'
			) {{ item.name }}
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
	name: 'select-box',
	directives: {
    ClickOutside
  },
	data: function() {
		return {
			selected: {
				name: '',
				value: '',
			}
		}
	},
	computed: {
		detailsEl: function() {
			return this.$el.getElementsByTagName('details')[0];
		},
	},
	props: {
		title: String,
		width: String,
		list: Array,
		alt: Boolean,
	},
	created: function() {
		this.selected.name = this.list[0].name;
		this.selected.value = this.list[0].value;
	},
	mounted: function() {
		this.popupItem = this.detailsEl;
	},
	methods: {
		selectItem(e) {
			this.selected.name = e.target.textContent;
			this.selected.value = e.target.dataset.value;

			this.$emit('selectItem', this.selected);

			this.detailsEl.removeAttribute('open');
		},
		closeMenu(){
			this.detailsEl.removeAttribute('open');
		}
	},
}
</script>

<style scoped>
.select-title {
	font-weight: 500;
	font-size: 10px;
	line-height: 18px;
	color: #8C8C8C;
	text-transform: uppercase;
}

details {
	position: relative;

	list-style: none;
	font-weight: normal;
	font-size: 16px;
}

.select-box {
	box-sizing: border-box;
	height: 40px;
	padding: 8px 10px;
	background-color: var(--color-secondary-button-background);
	color: #8C8C8C;
	border: 2px solid transparent;
	border-radius: 10px;
	cursor: pointer;
	position: relative;
	list-style: none;
}

.select-box-alt {
	background-color: #ffffff;
	color: var(--color-primary-dark);
}

:root[data-mode="dark"] .select-box-alt {
	background-color: #1d1d1d;
}

.select-box:hover {
	border: 2px solid rgba(255, 134, 142, 0.2);
}

summary::-webkit-details-marker {
	display: none;
}

summary:after {
	content: url(./../assets/dropdown.svg);
	position: absolute;
	right: 10px;
}

.select-menu {
	list-style: none;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	margin: 0;
	gap: 10px;

	position: absolute;
	box-sizing: border-box;
	width: 100%;
	max-height: 260px;
	top: 45px;
	right: 0;
	padding: 20px;

	background-color: #ffffff;
	border-radius: 30px;
	z-index: 10;
}

:root[data-mode="dark"] .select-menu {
	background-color: #3e3e3e;
}

.select-item {
	color: #8C8C8C;
	cursor: pointer;
}

@media screen and (max-width: 480px) {
	.select-box {
		width: 100% !important;
	}
}

</style>