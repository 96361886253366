export default class Vote {
	constructor() {
		this.API_KEY = process.env.VUE_APP_API_KEY;
	}

	get() {
		return fetch('https://api.thedogapi.com/v1/breeds', {
			method: 'GET',
			headers: {
				'x-api-key': this.API_KEY,
			},
		}) 
			.then(res => res.json())
			.catch(err => console.error(err));
	}

	search(breedName) {
		return fetch('https://api.thedogapi.com/v1/breeds/search?q=' + encodeURI(breedName), {
			method: 'GET',
			headers: {
				'x-api-key': this.API_KEY,
			},
		}) 
			.then(res => res.json())
			.catch(err => console.error(err));
	}
}