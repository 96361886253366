<template lang="pug">
.page-container
	.loading-spinner(v-if='isRetrievingData')
		clip-loader(color="#ff868e" size='100px')
	upload-modal(:hide='modalState' @close='toggleUploadModal')
	.page-header
		back-button
		.page-title
			span gallery
		.upload-btn(@click.stop='toggleUploadModal')
			.upload-pic
			span upload
	.filters-container
		select-box(title='order' :list='orderList' @selectItem='changeItemsOrder' :alt='true')
		select-box(title='type' :list='typeList' @selectItem='changeImageType' :alt='true')
		select-box(title='breed' :list='breedsList' @selectItem='changeItemsBreed' :alt='true')
		.limit-container
			select-box(title='limit' :list='limitList' @selectItem='changeItemsOnPage' :alt='true')
			.apply-btn(@click='searchImages')
	.tiles-container
		.tile(
			v-for='dog in images.slice(0, imageFilters.limit)' :key='dog.id'
			:style="{ 'background-image': 'url(' + dog.url + ')' }"
		)
			.tile-overlay
				.tile-center-btn(@click='toggleFav($event, dog.id)')
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import BackButton from '@/components/BackButton.vue';
import SelectBox from '@/components/SelectBox.vue';
import UploadModal from '@/components/UploadModal.vue';
import { Breed, Favourite, Image } from '@/services';

const BreedService = new Breed();
const FavouriteService = new Favourite();
const ImageService = new Image();

export default {
	name: 'breeds',
	components: {
		ClipLoader,
		BackButton,
		SelectBox,
		UploadModal,
	},
	data: function() {
		return {
			imageFilters: {
				order: 'RANDOM',
				mime_types: '',
				breed_id: '',
				limit: 5,
			},
			limitList: [{
				value: 5,
				name: '5 items per page',
			}, {
				value: 10,
				name: '10 items per page',
			}, {
				value: 15,
				name: '15 items per page',
			}, {
				value: 20,
				name: '20 items per page',
			}],
			breedsList: [{
				value: 0,
				name: 'None',
			}],
			typeList: [{
				name: 'All',
			}, {
				name: 'Static',
			}, {
				name: 'Animated',
			}],
			orderList: [{
				name: 'Random',
			}, {
				name: 'Desc',
			}, {
				name: 'Asc',
			}],
			breeds: [],
			images: [],
			modalState: true,
			isRetrievingData: true,
		}
	},
	created: async function() {
		this.$store.commit('setPage', 'gallery');
		
		await this.searchImages();

		let breeds = await BreedService.get();

		for (let breed of breeds) {
			this.breedsList.push({
				value: breed.id,
				name: breed.name,
			});
		}

		this.isRetrievingData = false;
	},
	methods: {
		encodeParams(paramsObj) {
			let encodedString = '';
			for (let i in paramsObj) {
				let value = paramsObj[i] + '';
				if (value.length > 0) {
					encodedString += i + '=' + value + '&';
				}
			}

			if (encodedString.endsWith('&')) {
				encodedString = encodedString.slice(0, encodedString.length - 1);
			}
			
			return encodedString;
		},
		async searchImages(){
			let encoded = this.encodeParams(this.imageFilters);
			this.images = await ImageService.search(encoded);
		},
		toggleUploadModal() {
			this.modalState = !this.modalState;
		},
		changeImageType(e) {
			if (e.name === 'Static') {
				this.imageFilters.mime_types = 'jpg,png'
			} else if (e.name === 'Animated') {
				this.imageFilters.mime_types = 'gif';
			} else {
				this.imageFilters.mime_types = '';
			}
		},
		changeItemsOrder(e) {
			this.imageFilters.order = e.name.toUpperCase();
		},
		changeItemsBreed(e) {
			this.imageFilters.breed_id = e.value;
		},
		changeItemsOnPage(e) {
			this.imageFilters.limit = e.value;
		},
		async toggleFav(e, picId) {
			if (e.target.classList.contains('favourite')) {
				let favId = e.target.getAttribute('data-fav-id');
				await FavouriteService.remove(favId);
			} else {
				let fav = await FavouriteService.add(picId);
				e.target.setAttribute('data-fav-id', fav.id);
			}

			e.target.classList.toggle('favourite');
		}
	}
};
</script>

<style scoped>
.page-header {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.page-title {
	width: 146px;
	height: 40px;
	background: var(--color-primary);
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.page-title span {
	color: #ffffff;
}

.page-title span,
.upload-btn span {
	font-weight: 500;

	text-align: center;
	letter-spacing: 2px;

	text-transform: uppercase;
}

.upload-btn {
	width: 143px;
	min-width: 143px;
	height: 40px;
	background-color: var(--color-tertiary-background);
	color: var(--color-primary);
	border-radius: 10px;
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	cursor: pointer;
}

.upload-pic {
	width: 16px;
	height: 16px;
	background-image: url(./../assets/upload-red.svg);
}

.upload-btn:hover {
	background-color: var(--color-primary);
	color: #ffffff;
}

.upload-btn:hover > .upload-pic {
	background-image: url(./../assets/upload-white.svg);
}

.filters-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-column-gap: 20px;
	grid-row-gap: 10px;
	padding: 10px 20px 20px 20px;

	background-color: var(--color-secondary-button-background);
	border-radius: 20px;
}

.tile-center-btn {
	background-image: url(./../assets/heart-red.svg);
	background-size: 20px;
}

.tile-center-btn.favourite {
	background-image: url(./../assets/heart-red-fill.svg);
}

.limit-container {
	display: flex;
	gap: 10px;
}

.limit-container .select-container {
	width: 100%;
}

.apply-btn {
	background-color: #FFFFFF;
	background-image: url(./../assets/update-red.svg);
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 10px;
	height: 40px;
	width: 40px;
	min-width: 40px;
	align-self: end;
	cursor: pointer;
}

:root[data-mode='dark'] .apply-btn {
	background-color: #1d1d1d;
}

:root[data-mode='dark'] .apply-btn:hover,
.apply-btn:hover {
	background-color: #FF868E;
	background-image: url(./../assets/update-white.svg);
}

@media screen and (min-width: 480px) {
	.upload-btn {
		width: 143px;
		min-width: 143px;
	}

	.filters-container {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		padding: 10px 20px 20px 20px;
	}
}

@media screen and (max-width: 480px) {
	.upload-btn,
	.apply-btn {
		width: 100%;
	}

	.filters-container {
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		padding: 10px;
	}

	.limit-container {
		flex-direction: column;
	}
}
</style>