<template lang="pug">
.page-container
	.loading-spinner(v-if='isRetrievingData')
		clip-loader(color="#ff868e" size='100px')
	.page-header
		back-button
		.page-title
			span dislikes
	.no-results-msg(v-if='!isRetrievingData && resultsList.length === 0')
		span No item found
	.tiles-container(v-else)
		.tile(
			v-for='dog in resultsList' :key='dog.id'
			:style="{ 'background-image': 'url(' + dog.url + ')' }"
		)
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import BackButton from '@/components/BackButton.vue';
import { Image, Vote } from '@/services';

const ImageService = new Image();
const VoteService = new Vote();

export default {
	name: 'search',
	components: {
		ClipLoader,
		BackButton,
	},
	data: function() {
		return {
			resultsList: [],
			isRetrievingData: true,
		}
	},
	beforeCreate: async function() {
		this.$store.commit('setPage', 'dislikes');

		let votes = await VoteService.get();			
		let likes = votes.filter(el => el.value === 0);

		let promises = likes.map(el => ImageService.get(el.image_id));
		let resps = await Promise.all(promises);
		this.resultsList = resps.flat();
		
		this.isRetrievingData = false;
	},
}
</script>

<style scoped>
.page-header {
	display: flex;
	gap: 10px;
}

.page-title {
	width: 146px;
	height: 40px;
	background: var(--color-primary);
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
}

.page-title span {
	font-weight: 500;
	text-align: center;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.no-results-msg {
	width: 600px;
	height: 60px;
	background-color: var(--color-secondary-button-background);
	border-radius: 10px;
	display: flex;
	padding: 0 20px;
	align-items: center;
}

.no-results-msg span {
	font-size: 16px;
	line-height: 24px;
	color: #8C8C8C;
}
</style>