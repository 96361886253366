<template lang="pug">
.page-container
	.loading-spinner(v-if='isRetrievingData')
		clip-loader(color="#ff868e" size='100px')
	.page-header
		back-button
		.page-title
			span search
	.search-title(v-if='!isRetrievingData && resultsList.length > 0')
		p Search results for: 
			b {{ searchQuery }}
	.no-results-msg(v-if='!isRetrievingData && resultsList.length === 0')
		span No item found
	.tiles-container(v-else)
		.tile(
			v-for='dog in resultsList' :key='dog.id'
			:title='dog.name'
			:style="{ 'background-image': 'url(' + dog.image + ')' }"
		)
			.tile-overlay
				.tile-botton-btn(@click='openBreed(dog.name)')
					span {{ dog.name }}
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import BackButton from '@/components/BackButton.vue';
import { Breed, Image } from '@/services';

const BreedService = new Breed();
const ImageService = new Image();

export default {
	name: 'search',
	components: {
		ClipLoader,
		BackButton,
	},
	data: function() {
		return {
			searchQuery: '',
			resultsList: [],
			isRetrievingData: true,
		}
	},
	watch:{
		async $route (to){
			if (to.path === '/search') {
				this.searchQuery = this.$route.query.b;
				await this.searchBreeds(this.searchQuery);
			}
		}
	},
	created: async function() {
		this.$store.commit('setPage', 'search');
		this.searchQuery = this.$route.query.b;

		await this.searchBreeds(this.searchQuery);
	},
	methods: {
		async searchBreeds(query) {
			this.isRetrievingData = true;

			this.resultsList = await BreedService.search(query);

			let promises = this.resultsList.map((el, i) => ImageService.search('breed_id=' + el.id) 
				.then(res => {
					if (res.length > 0) {
						this.resultsList[i].image = res[0].url;
					}
					
					return this.resultsList[i];
				})
			);

			let resps = await Promise.all(promises);
			this.resultsList = resps.flat();
			this.isRetrievingData = false;
		},
		openBreed(breedName) {
			this.$router.push({path: '/breeds/' + breedName});
		}
	},
}
</script>

<style scoped>
.page-header {
	display: flex;
	gap: 10px;
}

.page-title {
	width: 146px;
	height: 40px;
	background: var(--color-primary);
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.page-title span {
	color: #FFFFFF;
}

.page-title span {
	font-weight: 500;
	text-align: center;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.search-title {
	font-size: 20px;
	line-height: 29px;
	color: #8C8C8C;
	margin: 20px 0 0 0;
}

.search-title b {
	font-weight: 500;
	color: var(--color-primary-dark);
}

.no-results-msg {
	width: 600px;
	height: 60px;
	background-color: var(--color-secondary-button-background);
	border-radius: 10px;
	display: flex;
	padding: 0 20px;
	align-items: center;
}

.no-results-msg span {
	font-size: 16px;
	line-height: 24px;
	color: #8C8C8C;
}
</style>