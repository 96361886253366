<template lang="pug">
.welcome-container(:class='{sidebar: openedPage != "home" && windowWidth <= 1024, hide: openedPage != "home" && windowWidth <= 1024}')
	template(v-if='openedPage === "home" || windowWidth > 1024')
		header
			app-logo
			mode-switcher
		h1.title-content Hi intern!
		span Welcome to MSI 2021 Front-end test
		h2.title-content Lets start using The Dogs API
	.controls-container(v-else-if='openedPage != "home" && windowWidth <= 1024')
		mode-switcher
		.close-btn(@click='closeSidebar')

	.cards-container
		homepage-card(image='vote-table.svg' btnTitle='voting' bgColor='#B4B7FF' dest='voting' 
			:class='isThisPage("voting")' 
			@openPage='closeSidebar'
		)
		homepage-card(image='pet-breeds.svg' btnTitle='breeds' bgColor='#97EAB9' dest='breeds' 
			:class='isThisPage("breeds")' 
			@openPage='closeSidebar'
		)
		homepage-card(image='images-search.svg' btnTitle='gallery' bgColor='#FFD280' dest='gallery' 
			:class='isThisPage("gallery")' 
			@openPage='closeSidebar'
		)
</template>

<script>
import AppLogo from '@/components/AppLogo.vue';
import ModeSwitcher from '@/components/ModeSwitcher.vue';
import HomepageCard from '@/components/HomepageCard.vue';
import { windowWidth } from '@/mixins';

export default {
	name: 'main-section',
	mixins: [
		windowWidth,
	],
	components: {
		AppLogo,
		ModeSwitcher,
		HomepageCard,
	},
	data: function(){
		return {
			openedPage: 'home',
		};
	},
	created: function() {
		this.openedPage = this.$store.state.page;
		
		this.$store.subscribe(mutation => {
			if (mutation.type === 'setPage') {
				this.openedPage = mutation.payload; 
			}
		})	
	},
	methods:{
		isThisPage(page) {
			if (this.openedPage === page) return 'active';
		},
		openSidebar() {
			this.$el.classList.remove('hide');
		},
		closeSidebar() {
			if (this.windowWidth <= 1024) {
				this.$el.classList.add('hide');
			}
		},
	}
};
</script>

<style scoped>
	.welcome-container {
		font-weight: 500;

		text-align: left;	

		display: flex;
		flex-direction: column;
	}

	.welcome-container.sidebar {
		position: absolute;
		z-index: 100;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		background: var(--color-primary-background);
	}

	.controls-container {
		position: absolute;
		display: flex;
		gap: 20px;
		align-items: center;
		justify-content: flex-end;
	}

	.welcome-container.sidebar .close-btn {
		height: 60px;
		width: 60px;
		border-radius: 10px;
		background-image: url(./../assets/cross-red.svg);
		background-color: var(--color-primary-button-background);
		background-repeat: no-repeat;
		background-position: center;
		cursor: pointer;
	}

	.welcome-container.sidebar .cards-container {
		margin-top: 80px;
	}

	header {
		display: flex;
		justify-content: space-between;
	}

	.welcome-container h1 {
		font-size: 44px;
		line-height: 58px;
		margin: 80px 0 0 0;
	}

	.welcome-container h2,
	.welcome-container span {
		font-size: 20px;
		line-height: 28.9px;
		margin: 0;
	}

	.title-content {	
		color: var(--color-primary-dark);
	}

	.welcome-container span {
		color: #8C8C8C;
		margin: 10px 0 60px 0;
	}

	.cards-container {
		margin-top: 20px;
		display: flex;
		gap: 16px;
		text-align: center;
	}

	.hide {
		transform: translateX(calc(-100% - 20px));
	}

	@media screen and (min-width: 480px) and (max-width: 1024px) {
		.welcome-container.sidebar {
			padding: 30px;
		}

		.controls-container {
			width: calc(100% - 60px);
		}

		.cards-container {
			margin-right: 20px;
			align-self: center;
			width: max-content;
		}
	}

	@media screen and (max-width: 480px) {
		.welcome-container.sidebar {
			padding: 20px;
		}

		.controls-container {
			width: calc(100% - 40px);
		}

		.cards-container {
			flex-direction: column;
			gap: 20px;
		}
	}
</style>
