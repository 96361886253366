<template lang="pug">
.page-container
	.loading-spinner(v-if='isRetrievingData')
		clip-loader(color="#ff868e" size='100px')
	.page-header
		back-button
		.page-title.link(@click='openBreedsPage')
			span breeds
		.page-title.active-page
			span {{ breed.id }}
	.dog-image(:style="{ backgroundImage: 'url(' + image + ')' }")
		.dots-list(v-if='!isRetrievingData')
			.dot
			.dot.active-dot
			.dot
			.dot
			.dot
	.info-container(v-if='!isRetrievingData')
		.breed-name {{ breed.name }}
		.breed-desc {{ breed.bred_for }}
		.breed-info
			.col-half
				p
					span.info-title Temperament:
					br
					span.info {{ breed.temperament }}
			.col-half
				p
					span.info-title Height: 
					span.info {{ breed.height.metric }} cm at the withers
				p
					span.info-title Weight: 
					span.info {{ breed.weight.metric }} kgs
				p
					span.info-title Life span: 
					span.info {{ breed.life_span }}
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import BackButton from '@/components/BackButton.vue';
import { Breed, Image } from '@/services';

const BreedService = new Breed();
const ImageService = new Image();

export default {
	name: 'breed',
	components: {
		ClipLoader,
		BackButton,
	},
	data: function() {
		return {
			breed: {
				id: '?',
			},
			image: '',
			isRetrievingData: true,
		}
	},
	beforeCreate: async function() {
		this.$store.commit('setPage', 'breed');
		let breedName = this.$route.params.name;

		await BreedService.search(breedName) 
			.then(res => this.breed = res[0])
			.then(() => ImageService.get(this.breed.reference_image_id))
			.then(res => this.image = res.url)
			.catch(err => console.error(err));

		this.isRetrievingData = false;
	},
	methods: {
		openBreedsPage() {
			this.$router.push({path: '/breeds'});
		}
	},
}
</script>

<style scoped>
.page-header {
	display: flex;
	gap: 10px;
}

.page-title {
	height: 40px;
	background-color: var(--color-tertiary-background);
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.page-title span {
	font-weight: 500;

	text-align: center;
	letter-spacing: 2px;

	text-transform: uppercase;
}

.link {
	width: 146px;
	cursor: pointer;
	color: #FF868E;
}

.active-page {
	padding: 0 30px;
}

.link:hover,
.active-page {
	background: #FF868E;
	color: #FFFFFF;
}

.dog-image {
	position: relative;
	width: 100%;
	padding-bottom: 60%;
	border-radius: 20px;
	background-size: cover;
}

.dots-list {
	position: absolute;
	width: 90px;
	height: 30px;
	bottom: 0;
	left: 50%;
	margin: 0 0 -15px -45px;

	background: var(--color-secondary-background);
	border-radius: 20px;

	display: flex;
	gap: 5px;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.dot {
	width: 10px;
	height: 10px;
	background-color: #FBE0DC;
	border-radius: 10px;
}

.active-dot {
	background-color: #FF868E;
}

.info-container {
	position: relative;
	border: 2px solid var(--color-tertiary-background);
	box-sizing: border-box;
	border-radius: 20px;
	margin: 51px 0 75px 0;
}

.breed-name {
	position: absolute;
	width: max-content;
	padding: 5px 40px;
	background: var(--color-secondary-background);
	border-radius: 20px;

	left: 50%;
	top: -31px;
	transform: translate(-50%, 0);

	display: flex;
	align-items: center;

	font-weight: 500;
	font-size: 36px;
	text-align: center;
	color: var(--color-primary-dark);
}

.breed-desc {
	position: absolute;
	left: 50%;
	top: 31px;
	transform: translate(-50%, 0);

	font-weight: 500;
	font-size: 20px;
	text-align: center;
	color: #8C8C8C;
}

.breed-info {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;

	font-size: 16px;
	margin: 75px 40px 40px 40px;
}

.breed-info .col-half {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.breed-info .col-half p {
	margin: 0;
	line-height: 23px;
}

.info-title {
	font-size: 16px;
	color: var(--color-primary-dark);
}

.info {
	color: #8C8C8C;
}

@media screen and (min-width: 480px) and (max-width: 1024px) {
	.page-container {
		width: initial;
	}
}

@media screen and (max-width: 480px) {
	.info-container {
		margin: 51px 0 0 0;
	}

	.breed-name {
		font-size: 20px;
		padding: 15px 20px;
	}

	.breed-desc {
		font-size: 16px;
		width: 100%;
		top: 19px;
	}

	.breed-info {
		grid-template-columns: 1fr;
		grid-gap: 10px;
		margin: 60px 20px 20px 20px;
	}
}
</style>