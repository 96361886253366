<template lang="pug">
.page-container
	.loading-spinner(v-if='isRetrievingData')
		clip-loader(color="#ff868e" size='100px')
	.page-header
		back-button
		.page-title
			span favourites
	.no-results-msg(v-if='!isRetrievingData && resultsList.length === 0')
		span No item found
	.tiles-container(v-else)
		.tile(
			v-for='(dog, index) in resultsList' :key='dog.id'
			:style="{ 'background-image': 'url(' + dog.image.url + ')' }"
		)
			.tile-overlay
				.tile-center-btn(@click='removeFromFavs(dog.id, dog.image_id, index)')
	.logs-container
		log-item(v-for='item in logs' :key='item.id' :vote='item')
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import BackButton from '@/components/BackButton.vue';
import LogItem from '@/components/LogItem.vue';
import { Favourite } from '@/services';

const FavouriteService = new Favourite();

export default {
	name: 'search',
	components: {
		ClipLoader,
		BackButton,
		LogItem,
	},
	data: function() {
		return {
			resultsList: [],
			logs: [],
			isRetrievingData: true,
		}
	},
	beforeCreate: async function() {
		this.$store.commit('setPage', 'favourites');
		this.resultsList = await FavouriteService.get();
		
		this.isRetrievingData = false;
	},
	methods: {
		getTime() {
			let time = new Date();

			let hours = time.getHours();
			if ((hours + '').length < 2) hours = hours + '0';

			let minutes = time.getMinutes();
			if ((minutes + '').length < 2) minutes = '0' + minutes;

			return hours + ':' + minutes; 
		},
		async removeFromFavs(id, image_id, index) {
			await FavouriteService.remove(id); 
			
			this.logs.unshift({
				id: image_id,
				event: 'Favourites',
				detail: 'remove',
				time: this.getTime(),
			});

			if (this.logs.length > 5) this.logs.pop();
			this.resultsList.splice(index, 1);
		}
	}
}
</script>

<style scoped>
.page-header {
	display: flex;
	gap: 10px;
}

.page-title {
	width: 146px;
	height: 40px;
	background: #FF868E;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.page-title span {
	color: #FFFFFF;
}

.page-title span {
	font-weight: 500;
	text-align: center;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.no-results-msg {
	width: 600px;
	height: 60px;
	background-color: var(--color-secondary-button-background);
	border-radius: 10px;
	display: flex;
	padding: 0 20px;
	align-items: center;
}

.no-results-msg span {
	font-size: 16px;
	line-height: 24px;
	color: #8C8C8C;
}

.tile-center-btn {
	background-image: url(./../assets/heart-red-fill.svg);
	background-size: 20px;
}

.logs-container {
	margin-top: 40px;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
}
</style>