<template lang="pug">
.search-field
	form(@submit.prevent='searchBreed')
		input(type='text' placeholder='Search for breeds by name' v-model='searchValue')
	.search-ico
		img(src='@/assets/search-ico.svg')
</template>

<script>
export default {
	name: 'search-field',
	data: function() {
		return {
			searchValue: '',
		}
	},
	created: function() {
		if (this.$route.name === 'search') this.searchValue = this.$route.params.b; 
	},
	methods: {
		searchBreed(){
			this.$router.push({
				path: '/search',
				query: {
					b: this.searchValue, 
				},
			})
				.catch(() => {})
		}
	}
}
</script>

<style scoped>
.search-field {
	position: relative;
	height: 60px;
	width: 100%;
}

input {
	box-sizing: border-box;
	width: 100%;
	background-color: var(--color-secondary-background);
	border: 2px solid transparent;
	border-radius: 20px;
	padding: 13px 60px 13px 20px;
	outline: none;
	color: var(--color-primary-dark);
	font-size: 20px;
	line-height: 30px;
}

input::placeholder {
	font-size: 20px;
	line-height: 30px;

	color: #8C8C8C;
}

input:hover {
	border-color: #FBE0DC;
}

input:hover::placeholder {
	color: transparent;
}

.search-field.active input,
input:active {
	border-color: #FF868E;
}

input:active {
	color: var(--color-primary-dark);
}

.search-ico {
	position: absolute;
	width: 40px;
	height: 40px;

	bottom: 10px;
	right: 10px;
	
	background: rgba(255, 134, 142, 0.2);
	border-radius: 10px;
	display: flex;
	pointer-events: none;
}

.search-ico img {
	object-fit: none;
}
</style>